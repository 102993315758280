<template>
  <div>
    <cbs-objects-tbl meta-filter="cube" title="CUBES" />
  </div>
</template>

<script>
import CbsObjectsTbl from '@/cubus/components/objects/CbsObjectsTbl.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsObjectCube',
  components: {
    CbsObjectsTbl,
  },
  created() {
    useCubus.setPageTitle('Cubes')
  },
}
</script>

<style scoped>

</style>
